<template>
  <form novalidate @submit.prevent="validateUser">
    <div class="md-layout">
      <div class="md-layout-item md-size-100">
      <md-tabs :md-active-tab="current_tab">
        <md-tab id="tab-documento" md-label="Documento">
          <md-card >
            <md-card-header>
              <md-card-header-text v-if="view">
                 <div v-if="newSale" class="md-title">Nueva Venta</div>
                  <div v-else class="md-title">Editando Venta</div>
              </md-card-header-text>
              <md-card-header-text v-else>
                 <div class="md-title">Ver Venta</div>
              </md-card-header-text>
              <md-card-header-text>
                <div class="md-title period">
                  <span v-if="!instance.id">{{ mes }}</span>
                  <span v-if="instance.id" style="  text-transform: capitalize;">
                  {{instance.period_date|to_text_date}}</span>
                </div>
              </md-card-header-text>
              <md-menu md-size="big" md-direction="bottom-end">
                <md-button class="md-icon-button"
                  @click="go_to('/detalle_ventas')">
                  <md-icon>keyboard_backspace</md-icon>
                </md-button>
              </md-menu>
            </md-card-header>
            <md-card-content>
              <md-card-actions>
                <md-button v-if="view"
                  type="submit" class="md-primary" :disabled="sending">Guardar cambios</md-button>
              </md-card-actions>
              <div class="md-layout md-gutter">
                  <div class="md-layout-item md-small-size-100">
                    <md-checkbox v-model="instance.status">ACTIVA</md-checkbox>
                  <span v-if="instance.status" style="color: #ff5252;
                  font-weight: 500;" for="services">SÍ</span>
                  <span v-if="!instance.status" style="color: #ff5252;
                  font-weight: 500;" for="services">NO</span>
                  </div>
                </div>
              <div class="md-layout md-gutter">
                <div class="md-layout-item md-small-size-100" >
                  <md-field :class="getValidationClass('date_invoice')" class="md-focused">
                    <label for="date_invoice" >Fecha factura</label>
                    <md-input type="date"  v-on:blur="check_date"
                    id="date_invoice" v-model="instance.date_invoice"
                    :disabled="sending || !view" />
                  </md-field>
                  <span class="md-error"
                    v-if="!$v.instance.date_invoice.required">La fecha es requerida</span>
                </div>
                <div class="md-layout-item md-small-size-100">
                  <md-field :class="getValidationClass('dte_serie')">
                    <label for="dte_serie" >Serie</label>
                    <md-input type="text" name="dte_serie"
                    id="dte_serie" v-model="instance.dte_serie" :disabled="sending || !view" />
                  </md-field>
                  <span class="md-error"
                      v-if="!$v.instance.dte_serie.required">
                      La serie de la factura es requerida</span>
                </div>
                <div class="md-layout-item md-small-size-100" v-if="view">
                  <md-field :class="getValidationClass('dte_numero')">
                    <label for="name">Número</label>
                    <md-input name="dte_numero"
                      id="dte_numero" type="number"
                      v-model="instance.dte_numero" :disabled="sending || !view" />
                  </md-field>
                  <span class="md-error"
                      v-if="!$v.instance.dte_numero.required">
                      EL número de la factura es requerido</span>
                </div>
              </div>
              <div v-if="instance.status">
                <div class="md-layout md-gutter">
                  <div class="md-layout-item md-small-size-100">
                    <md-autocomplete autocomplete="off"
                    :class="getValidationClass('customer_name')"
                    id="customer_name"
                    :disabled="sending || !view"
                    v-model="instance.customer_name"
                    md-input-name="full_name"
                    :md-options="list_customer"
                    @md-selected="set_customer"
                    @md-changed="get_list_customers" >
                      <label>Cliente</label>
                      <template
                      slot="md-autocomplete-item" slot-scope="{ item, term }">
                        <md-highlight-text :md-term="term">{{ item.full_name }}</md-highlight-text>
                      </template>
                    </md-autocomplete>
                    <span class="md-error"
                    v-if="!$v.instance.customer_name.required">EL cliente es requerido</span>
                  </div>
                    <div class="md-layout-item md-small-size-100">
                      <md-field :class="getValidationClass('type_document')">
                        <label for="type_document">Tipo documento</label>
                        <md-select v-model="instance.type_document"
                        name="movie"
                        id="movie"
                        :disabled="sending || !view"
                        >
                          <md-option :value="item.value"
                          v-for="(item, key) in choosen_type_document"
                          :key="key">{{item.text}}</md-option>
                        </md-select>
                      </md-field>
                      <span class="md-error"
                        v-if="!$v.instance.type_document.required">
                        El tipo de documento es requerido</span>
                    </div>
                    <div class="md-layout-item md-small-size-100">
                      <md-autocomplete autocomplete="off"
                      id="account_name"
                      :disabled="sending"
                      v-model="instance.account_name"
                      :md-options="list_account"
                      @md-selected="set_account"
                      @md-changed="get_list_accounts" >
                        <label>Cuenta contable</label>
                        <template
                        slot="md-autocomplete-item" slot-scope="{ item, term }">
                          <md-highlight-text :md-term="term">{{ item.name }}</md-highlight-text>
                        </template>
                      </md-autocomplete>
                    </div>
                </div>
                <div class="md-layout md-gutter">
                  <div class="md-layout-item md-small-size-100" v-if="view">
                    <md-field >
                      <label for="services">Total servicios</label>
                      <md-input name="services" id="services"  @keyup="get_total_afecto()"
                      @focus="$event.target.select()"
                        autocomplete="services" v-model="instance.services" :disabled="sending" />
                    </md-field>
                  </div>
                  <div class="md-layout-item md-small-size-100" v-else>
                    <md-field >
                      <label for="services">Total servicios</label>
                      <md-input name="services" id="services" type="number"
                        readonly
                        autocomplete="services" v-model="instance.services" :disabled="sending" />
                    </md-field>
                  </div>
                  <div class="md-layout-item md-small-size-100" v-if="view">
                    <md-field>
                      <label for="goods">Total bienes</label>
                      <md-input name="goods" @keyup="get_total_afecto()"
                      @focus="$event.target.select()"
                      id="goods" v-model="instance.goods" type="number" :disabled="sending" />
                    </md-field>
                  </div>
                  <div class="md-layout-item md-small-size-100" v-else>
                    <md-field>
                      <label for="goods">Total bienes</label>
                      <md-input name="goods" readonly
                      id="goods" v-model="instance.goods" type="number" :disabled="sending" />
                    </md-field>
                  </div>
                  <div class="md-layout-item md-small-size-100">

                  </div>
                </div>
                <div class="md-layout md-gutter">
                  <div class="md-layout-item md-small-size-100" v-if="view">
                    <md-field >
                      <label for="exempt_services">Total servicios exento</label>
                      <md-input name="exempt_services" id="exempt_services" type="number"
                        @keyup="get_exento_services"
                      @focus="$event.target.select()"
                      v-model="instance.exempt_services" :disabled="sending" />
                    </md-field>
                  </div>
                  <div class="md-layout-item md-small-size-100" v-else>
                    <md-field >
                      <label for="exempt_services">Total servicios exento</label>
                      <md-input name="exempt_services" id="exempt_services" type="number"
                       readonly
                      v-model="instance.exempt_services" :disabled="sending" />
                    </md-field>
                  </div>
                  <div class="md-layout-item md-small-size-100" v-if="view">
                    <md-field >
                      <label for="exempt_goods">Total bienes exento</label>
                      <md-input name="exempt_goods"
                      id="exempt_goods" @keyup="get_exento_services"
                      @focus="$event.target.select()"
                      v-model="instance.exempt_goods" type="number" :disabled="sending" />
                    </md-field>
                  </div>
                  <div class="md-layout-item md-small-size-100" v-else>
                    <md-field >
                      <label for="exempt_goods">Total bienes exento</label>
                      <md-input name="exempt_goods" readonly
                      id="exempt_goods"
                      v-model="instance.exempt_goods" type="number" :disabled="sending" />
                    </md-field>
                  </div>
                  <div class="md-layout-item md-small-size-100">
                  </div>
                </div>
                <div class="md-layout md-gutter">
                  <div class="md-layout-item md-size-33 md-small-size-100" v-if="view">
                    <md-field :class="getValidationClass('amount')">
                      <label for="amount">Total</label>
                      <md-input name="amount" id="amount" type="number"
                        autocomplete="amount" readonly
                        v-model="instance.amount" :disabled="sending" />
                      <span class="md-error"
                      v-if="!$v.instance.amount.required">El total es requerido</span>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-33 md-small-size-100" v-else>
                    <md-field :class="getValidationClass('amount')">
                      <label for="amount">Total</label>
                      <md-input name="amount" id="amount" type="number"
                        readonly autocomplete="amount"
                        v-model="instance.amount" :disabled="sending" />
                      <span class="md-error"
                      v-if="!$v.instance.amount.required">El total es requerido</span>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-33 md-small-size-100" v-if="view">
                    <md-field :class="getValidationClass('iva')">
                      <label for="iva">IVA</label>
                      <md-input name="iva" readonly
                      id="iva" v-model="instance.iva" type="number" />
                      <span class="md-error"
                      v-if="!$v.instance.iva.required">El IVA es requerido</span>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-33 md-small-size-100" v-else>
                    <md-field :class="getValidationClass('iva')">
                      <label for="iva">IVA</label>
                      <md-input name="iva" readonly
                      id="iva" v-model="instance.iva" type="number" />
                      <span class="md-error"
                      v-if="!$v.instance.iva.required">El IVA es requerido</span>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-33 md-small-size-100" v-if="view">
                    <md-field :class="getValidationClass('base')">
                      <label for="base">Base</label>
                      <md-input name="base" readonly
                      id="base" v-model="instance.base" type="number"  />
                      <span class="md-error"
                      v-if="!$v.instance.base.required">La base es requerida</span>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-33 md-small-size-100" v-else>
                    <md-field :class="getValidationClass('base')">
                      <label for="base">Base</label>
                      <md-input name="base" readonly
                      id="base" v-model="instance.base" type="number" disabled />
                      <span class="md-error"
                      v-if="!$v.instance.base.required">La base es requerida</span>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-33 md-small-size-100" >
                    <div class="md-layout-item md-small-size-100">
                      <md-checkbox v-model="iva_sobre_total" @change="get_total_afecto()">
                      IVA SOBRE TOTAL</md-checkbox>
                    <span v-if="iva_sobre_total" style="color: #ff5252;
                    font-weight: 500;" for="services">SÍ</span>
                    <span v-if="!iva_sobre_total" style="color: #ff5252;
                    font-weight: 500;" for="services">NO</span>
                    </div>
                  </div>
                </div>
                <md-button v-if="view"
                  type="submit" class="md-primary" :disabled="sending">Guardar cambios</md-button>
              </div>
            </md-card-content>
            <md-progress-bar md-mode="indeterminate" v-if="sending" />
          </md-card>
        </md-tab>
        <md-tab id="tab-partida" md-label="Partida"
            :md-disabled="tab" >
            <md-card-header>
              <md-card-header-text>
                <h3 for="total" >
                Total Documento: {{ this.instance.amount|format_number }}</h3>
              </md-card-header-text>
              <md-card-header-text >
                Número partida:
                <span class="headline" v-if="instance.partida_obj">
                 {{instance.partida_obj.numero_partida}} </span>
              </md-card-header-text>
              <md-card-header-text>
                Fecha partida:
                <span v-if="instance.partida_obj">
                {{instance.partida_obj.date_partida|to_date }} </span>
              </md-card-header-text>
              <md-menu md-size="big" md-direction="bottom-end">
                <br>
                <md-button class="md-icon-button"
                  @click="go_to(`/detalle_ventas`)">
                  <md-icon>keyboard_backspace</md-icon>
                </md-button>
              </md-menu>
            </md-card-header>
            <div v-if="instance.partida">
              <basePartida :id_partida = "instance.partida" :view = "view"
              v-if="is_show_component"/>
            </div>

              <div class="md-layout-item md-small-size-50" >
                <md-field v-if="hidden">
                  <md-input name="id"
                    id="id"
                    v-model="instance.partida" :disabled="sending" />
                </md-field>
              </div>

               <div class="moveRigth" v-if="view">
                <md-button class="md-primary"
                  @click="confirmPartida()" v-if="newSale">
                  Guardar Partida
                </md-button>
                <md-button class="md-primary" @click="confirmPartida()" v-else>
                  Guardar Cambios
                </md-button>
              </div>
              <div class="md-layout-item md-small-size-100 margin" v-if="view">
                <md-field>
                  <label for="descripcion_partida">Descripción partida</label>
                    <md-input name="descripcion_partida"
                      id="descripcion_partida"
                      v-model="instance.descripcion" :disabled="sending" />
                </md-field>
              </div>
              <div class="md-layout-item md-small-size-100 margin" v-else>
                <md-field>
                  <label for="descripcion_partida">Descripción partida</label>
                    <md-input name="descripcion_partida"
                      id="descripcion_partida" readonly
                      v-model="instance.descripcion" :disabled="sending" />
                </md-field>
              </div>

        </md-tab>
      </md-tabs>
      </div>
      <md-snackbar
        :md-active.sync="userSaved">El documento {{ docuemt }} ha sido actualizado!</md-snackbar>
      <md-snackbar
        :md-active.sync="partidaSaved">La partida ha sido actualizada!</md-snackbar>
    </div>
  </form>
</template>
<script>
/* eslint-disable */
import Swal from 'sweetalert2';
import moment from 'moment';
import { validationMixin } from 'vuelidate'
import {
  required,
  email,
} from 'vuelidate/lib/validators'

export default {
  name: 'FormDetalleVetas',
  created(){
    if (this.$route.params.invoice_id) {
      this.instance.id = this.$route.params.invoice_id
      this.retrieveInvoice();
    }else if (this.$route.params.invoice_idview) {
      this.instance.id = this.$route.params.invoice_idview
      this.view = false
      this.retrieveInvoice();
    }
    this.retrievePeriod();

    let book_diary = this.get_data_storage('book_diary')
    //this.$store.state.book_diary = d.id
    if (book_diary) {
      this.$store.dispatch('set_book_diary', { bookDiary: book_diary });
    }
    if (!book_diary) {
      Swal.fire({
        title: "Aviso",
        text: "Debe de ingresar libro diario, para crear partidas",
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$router.push('/libro_diario');
        }
      });
    }
  },
  mixins: [validationMixin],
  components: {
    basePartida: () => import('../components/BasePartida.vue'),
  },
  data() {
    return {
      list_customer: [],
      list_store: [],
      list_rubros: [],
      list_libros: [],
      list_account: [],
      url_customer: 'customers/',
      url_stores: 'stores/',
      url_rubros: 'rubros/',
      url_libros: 'sale_book/',
      url_accounts: 'accounting_account/',
      base: process.env.VUE_APP_BASE_URL,
      mes: '',
      instance: {
        customer_name: null,
        amount: 0.00,
        date_invoice: null,
        type_document: null,
        iva: 0.00,
        status: true,
        goods: 0.00,
        services: 0.00,
        exempt_goods: 0.00,
        exempt_services: 0.00,
        dte_numero: null,
        dte_serie: null,
        base: 0.00,
        date_partida: null,
        numero_partida: "1",
        tipo_partida: "CXC",
        descripcion: "",
        book_diary: "",
        id: '',
        partida_obj: {}
      },
      totDocumento: 0.00,
      url: 'sale_book_detail/',
      url_diarybook: 'diary_book_detail/',
      url_period: 'periods/',
      current_tab: "tab-documento",
      hidden: false,
      tab: true,
      view: true,
      is_show_component: true,
      userSaved: false,
      newSale: true,
      sending: false,
      docuemt: null,
      crear_partida: true,
      partidaSaved: false,
      anulada: false,
      iva_sobre_total: false,
      choosen_type_document: [
      {value:'D', text: 'DTE FACTURA'},
      {value:'F', text: 'FACTURA'},
      {value:'P', text: 'DTE FACTURA PEQUEÑO CONTRIBUYENTE'},
      {value:'Q', text: 'FACTURA PEQUEÑO CONTRIBUYENTE'},
      {value:'R', text: 'DTE NOTA CRÉDITO'},
      {value:'G', text: 'NOTA CRÉDITO'},
      {value:'B', text: 'DTE NOTA DÉBITO'},
      {value:'K', text: 'NOTA DÉBITO'},
      {value:'Y', text: 'FACTURA CAMBIARIA'},
      {value:'A', text: 'DTE FACTURA CAMBIARIA'},
      {value:'J', text: 'DTE FACTURA CAMBIARIA PEQUEÑO CONTRIBUYENTE'},
      {value:'M', text: 'COMPROBANTE'},
      {value:'E', text: 'DTE FACTURA ESPECIAL'},
      {value:'S', text: 'FACTURA ESPECIAL'},
      ]
    };
  },
  validations: {
    instance: {
      customer_name: {
        required,
      },
      amount: {
        required,
      },
      iva: {
        required,
      },
      date_invoice: {
        required,
      },
      type_document: {
        required,
      },
      dte_serie: {
        required,
      },
      base: {
        required,
      },
      dte_numero: {
        required,
      },
    },
  },
  methods: {
    reset_component () {
      this.is_show_component = false
      this.$nextTick(()=> {
        this.is_show_component = true
      })
    },
    get_total_afecto (iva_incluido=true){      
      if (iva_incluido){
        if (this.instance.type_document === 'P' || this.instance.type_document === 'Q' ){
          let tservices = this.instance.services
          if (!tservices){
            tservices = 0
          }
          let tgoods = this.instance.goods
          if (!tgoods){
            tgoods = 0
          }
          let total = (parseFloat(tservices) + parseFloat(tgoods)).toFixed(5)
          this.instance.base = parseFloat(total).toFixed(5)
          this.instance.amount = parseFloat(total).toFixed(5)
        }
        else {
          let iva_porcentual = parseFloat(this.$store.state.company_obj.porcentaje_IVA/100)
          let siva = parseFloat(this.$store.state.company_obj.porcentaje_IVA/100)+1
          //Validar
          let tservices = this.instance.services
          if (!tservices){
            tservices = 0
          }
          let tgoods = this.instance.goods
          if (!tgoods){
            tgoods = 0
          }
          
          let total = parseFloat(parseFloat(tservices) + parseFloat(tgoods)).toFixed(5)
          this.instance.iva = this.round_up(parseFloat(( total / siva)*iva_porcentual).toFixed(5), 5)
          this.instance.base = parseFloat( total / siva).toFixed(5)

          if (this.iva_sobre_total){
            this.instance.iva = this.round_up(parseFloat(total*iva_porcentual).toFixed(5), 5)
            this.instance.base = parseFloat( total - this.instance.iva).toFixed(5)
          }
          
          this.instance.amount = total
        }
      }
    },
    get_exento_services (iva_incluido=true){
      if (iva_incluido){
        let tservicesE = this.instance.exempt_services
        if (!tservicesE){
          tservicesE = 0
        }
        let tgoodsE = this.instance.exempt_goods
        if (!tgoodsE){
          tgoodsE = 0
        }
        let total = (parseFloat(tservicesE) + parseFloat(tgoodsE)).toFixed(5)
        this.instance.base = parseFloat( total ).toFixed(5)
        this.instance.amount = parseFloat(this.instance.base).toFixed(5)
        }
    },
    round_up(n, decimals=0) {
       let multiplier = (10 ** decimals)
       return Math.ceil(n * multiplier) / multiplier
    },
    set_customer (obj) {
      this.instance.customer_name = obj.full_name
      this.instance.customer = obj.id
      this.get_document()
    },
    set_rubro (obj) {
      this.instance.rubro_name = obj.name
      this.instance.rubro = obj.id
    },
    set_account(obj) {  
      this.instance.account_name = obj.name;
      this.instance.account = obj.id;
    },
    get_list_accounts(searchTerm) {
      const payload = {};
      if (searchTerm) {
        payload.toke = searchTerm
        payload.filter = "True"
        payload.accountSearch = "1"
      }
      filter: "True"
      const self = this;
      this.sendRequest(payload, this.url_accounts).then((data) => {
        self.list_account = data.data;
      }).catch((error) => {
        console.log(error);
        this.$swal.fire('Error!', 'No fue posible obtener los registros.', 'error');
      });
      return null;
    },
    go_to(url) {
      this.$router.push(url);
    },
    save_storage(key, data) {
      localStorage.setItem([key], JSON.stringify(data));
    },
    get_data_storage(name) {
      return JSON.parse(localStorage.getItem(name));
    },
    check_date(){
      const self = this
      let date_i = moment(self.$store.state.date_period_i)
      if (self.instance.id){
        date_i = moment(self.instance.period_date)
      }
      let dif = date_i.diff(self.instance.date_invoice, 'days')

      if (dif >= 1){
        self.$swal.fire('Error!', 'Fecha de factura es de un mes anterior.', 'error')
        return false
      }else if (dif <= -31){
        self.$swal.fire('Error!', 'Fecha de factura es de un mes posterior.', 'error')
        return false
      }else if (isNaN(dif)){
        self.$swal.fire('Error!', 'Ingreso una fecha errónea.', 'error')
        return false
      }
    },
    get_document(){
      const self = this
      if (self.instance.date_invoice && self.instance.dte_serie
        && self.instance.dte_numero){
          self.verify_document()
        }
    },
    verify_document(){
      if (this.instance.id) return false
      const self = this
      const payload = {
        verify: true,
        date_invoice: this.instance.date_invoice,
        dte_serie: this.instance.dte_serie,
        dte_numero: this.instance.dte_numero,
        company: this.$store.state.company_obj.id,
        customer: this.instance.customer,
      }
      this.postRequest(payload).then(function (data) {

      }).catch(function (error) {
        self.sending = false
        if (typeof (error.response.data) === 'object') {
          const result = error.response.data
          Object.entries(result).forEach(
            ([key, value]) => self.$swal.fire('Error!', key + ': ' + value, 'error')
          )
          return false
        }
        self.$swal.fire('Error!', error.response.data, 'error')
        return false
      })
    },
    async retrievePeriod () {
      const self = this
      const payload = {
        id: localStorage.getItem('periodSale')
      }
      await this.retrieveRequestPeriod(this.url_period, payload).then(function (data) {
        //Fecha inicio periodo
        self.mes = data.data.period
        //self.$store.state.date_period_i = data.data.date
        self.$store.dispatch('set_date_period_i', { datePeriodI: data.data.date });
      }).catch(function (error) {
        console.log(error)
        self.handlerError(error)
      })
    },
    confirmPartida() {
      const self = this;
      if (parseFloat(this.diferencia) >= 0.01) {
        self.$swal.fire('Error!', 'Tiene diferencia en la partida.', 'error')
        return false
      }
      const payload = this.instance.partida_obj
      this.sending = true
      if (payload.id) {
        this.putRequestEncabezadoPartida(payload).then(function (data) {
          self.partidaSaved = true
          self.sending = false
          self.current_tab = "tab-documento"

        }).catch(function (error) {
          self.sending = false
          if (typeof (error.response.data) === 'object') {
            const result = error.response.data
            Object.entries(result).forEach(
              ([key, value]) => self.$swal.fire('Error!', key + ': ' + value, 'error')
            )
            return false
          }
          self.$swal.fire('Error!', error.response.data, 'error')
        })
        return
      }
    },
    handlerError: (error) => {
      const result = error.response.data;
      const self = this;
      if (typeof (error.response.data) === 'object') {
        Object.entries(result).forEach(([key, value]) => { window.$swal.fire('Error!', `${key}: ${value}`, 'error'); });
        return false;
      }
      this.$swal.fire('Error!', result, 'error');
      return false;
    },
    list() {
      let payload = {}
      const self = this;
      this.sendRequest(payload, this.url).then((data) => {
        self.list_data = data.data;
      }).catch((error) => {
        console.log(error);
        this.$swal.fire('Error!', 'No fue posible obtener los registros.', 'error');
      });
      return null;
    },
    get_list_customers(searchTerm) {
      const payload = {
        token: searchTerm,
        filter_customer: "True"
      };
      const self = this;
      this.sendRequest(payload, this.url_customer).then((data) => {
        self.list_customer = data.data;
      }).catch((error) => {
        console.log(error);
        this.$swal.fire('Error!', 'No fue posible obtener los registros.', 'error');
      });
      return null;
    },
    get_list_stores(searchTerm=null) {
      const payload = {};
      if (searchTerm) {
        payload.toke = searchTerm
        payload.filter = "True"
      }
      filter: "True"
      const self = this;
      this.sendRequest(payload, this.url_stores).then((data) => {
        self.list_store = data.data;
      }).catch((error) => {
        console.log(error);
        this.$swal.fire('Error!', 'No fue posible obtener los registros.', 'error');
      });
      return null;
    },
    get_list_rubros(searchTerm=null) {
      let payload = {};
      if (searchTerm) {
        payload.toke = searchTerm
        payload.filter = "True"
      }
      const self = this;
      this.sendRequest(payload, this.url_rubros).then((data) => {
        self.list_rubros = data.data;
      }).catch((error) => {
        console.log(error);
        this.$swal.fire('Error!', 'No fue posible obtener los registros.', 'error');
      });
      return null;
    },
    get_list_libros(searchTerm=null) {
      const payload = {};
      if (searchTerm) {
        payload.toke = searchTerm
        payload.filter = "True"
      }
      filter: "True"
      const self = this;
      this.sendRequest(payload, this.url_rubros).then((data) => {
        self.list_libros = data.data;
      }).catch((error) => {
        console.log(error);
        this.$swal.fire('Error!', 'No fue posible obtener los registros.', 'error');
      });
      return null;
    },
    getValidationClass (fieldName) {
      const field = this.$v.instance[fieldName]

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },
    clearForm () {
      this.$v.$reset()
    },
    validateUser () {
      if (!this.instance.status){
        this.saveOrUpdate()
      }else{
        this.$v.$touch()
        if (!this.$v.$invalid) {
          this.saveOrUpdate()
        }
      }
    },
    saveOrUpdate () {
      if (!this.instance.dte_serie) {
        this.$swal.fire('Error!', 'Serie es requerido.', 'error')
        return false
      }
      if (!this.instance.dte_numero) {
        this.$swal.fire('Error!', 'Numero es requerido.', 'error')
        return false
      }
      if (!this.instance.date_invoice) {
        this.$swal.fire('Error!', 'Fecha es requerida.', 'error')
        return false
      }
      const self = this
      let date_i = moment(self.$store.state.date_period_i)
      if (this.instance.id){
        date_i = moment(this.instance.period_date)
      }
      let dif = date_i.diff(self.instance.date_invoice, 'days')
      if (dif >= 1){
        self.$swal.fire('Error!', 'Fecha de factura es de un mes anterior.', 'error')
        return false
      }else if (dif <= -31){
        self.$swal.fire('Error!', 'Fecha de factura es de un mes posterior.', 'error')
        return false
      }else if (isNaN(dif)){
        self.$swal.fire('Error!', 'Ingreso una fecha errónea.', 'error')
        return false
      }

      const payload = this.instance
      
      if (!payload.services){
        payload.services = 0
      }
      if (!payload.goods){
        payload.goods = 0
      }
      this.sending = true
      if (!this.instance.exempt_services) {
        this.instance.exempt_services = 0
      }
      if (!this.instance.exempt_goods) {
        this.instance.exempt_goods = 0
      }
      if (this.iva_sobre_total) {
        payload.iva_sobre_total = "True"
      }
      if (this.instance.id) {
        this.putRequest(payload).then(function (data) {
          self.docuemt = `${self.instance.dte_numero}`
          self.instance = data.data
          self.userSaved = true
          self.sending = false
          self.reset_component()
          self.clearForm()
        }).catch(function (error) {
          self.sending = false
          if (typeof (error.response.data) === 'object') {
            const result = error.response.data
            Object.entries(result).forEach(
              ([key, value]) => self.$swal.fire('Error!', key + ': ' + value, 'error')
            )
            return false
          }
          self.$swal.fire('Error!', error.response.data, 'error')
        })
        return
      }
      payload.company = this.$store.state.company_obj.id
      payload.store = this.$store.state.store_obj.id
      payload.period = localStorage.getItem('periodSale')
      payload.date_i = self.$store.state.date_period_i
      payload.book_diary = this.$store.state.book_diary.id
      if (!this.instance.type_document){
        this.instance.type_document = 'D'
        payload.type_document = 'D'
      }
      Swal.fire({
        title: "Aviso",
        text: "¿Desea crear partida?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Yes",
        cancelButtonText: "No"
      }).then((result) => {
        if (result.isConfirmed) {
            payload.crear_partida = this.crear_partida
            payload.office = localStorage.getItem('office')

            this.totDocumento = this.instance.amount
            let d = new Date();
            let day = d.getDate();
            let month = d.getMonth()+1;
            let year = d.getFullYear();
            let fecha = year + '-' + month + '-' + day
            payload.date_partida = fecha

            this.postRequest(payload).then(function (data) {

              self.docuemt = `${self.instance.dte_numero}`
              self.userSaved = true
              self.sending = false
              self.instance = data.data
              // self.instance.id_partida = data.data.id
              if (self.instance.partida){
                self.tab = false
              }
              self.current_tab = "tab-partida"
            //self.clearForm()
          }).catch(function (error) {
            self.sending = false
            if (error.response){
              self.$swal.fire('Error!', error, 'error')
            }
            if (typeof (error.response.data) === 'object') {
              const result = error.response.data
              Object.entries(result).forEach(
                ([key, value]) => self.$swal.fire('Error!', key + ': ' + value, 'error')
              )
              return false
            }
            self.$swal.fire('Error!', error.response.data, 'error')
          })
      }else {
        this.postRequest(payload).then(function (data) {
            self.instance = data.data
            self.docuemt = `${self.instance.dte_numero}`
            self.userSaved = true
            self.sending = false
          }).catch(function (error) {
          self.sending = false
          if (typeof (error.response.data) === 'object') {
            const result = error.response.data
            Object.entries(result).forEach(
              ([key, value]) => self.$swal.fire('Error!', key + ': ' + value, 'error')
            )
          return false
          }
          self.$swal.fire('Error!', error.response.data, 'error')
        })
      }
      });

    },
    empty(){
      this.instance = {
        customer_name: null,
        amount: 0.00,
        date_invoice: null,
        type_document: null,
        iva: 0.00,
        goods: 0.00,
        services: 0.00,
        exempt_goods: 0.00,
        exempt_services: 0.00,
        dte_numero: null,
        dte_serie: null,
        base: 0.00,
        rubro_name: null,
        rubro: null,
        date_partida: null,
        numero_partida: "1",
        tipo_partida: "CXC",
        descripcion: "",
        book_diary: "",
        id: '',
        //id_partida: ''
      }
    },
    async retrieveInvoice () {
      const self = this
      let payload = {
        id: this.instance.id,
      }
      await this.retrieveRequest(this.url, payload).then(function (data) {
        self.newSale = false
        self.instance = data.data
        // self.instance.id_partida = data.data.partida
        self.instance.date_partida = data.data.partida_obj.date_partida
        self.instance.numero_partida = data.data.partida_obj.numero_partida
        self.instance.tipo_partida = data.data.partida_obj.tipo_partida
        self.instance.descripcion = data.data.partida_obj.descripcion
        self.totDocumento = self.instance.amount
        if (self.instance.partida){
          self.tab = false
        }
      }).catch(function (error) {
        console.log(error)
        self.handlerError(error)
      })
    },
    async sendRequest(payload, url) {
      const result = await this.$http.get(url,{params:  payload});
      return result;
    },
    async postRequest (payload) {
      const result = await this.$http.post(this.url, payload)
      return result
    },
    async putRequest (payload) {
      const result = await this.$http.put(`${this.url}${payload.id}/`, payload)
      return result
    },
    async putRequestEncabezadoPartida (payload) {
      const result = await this.$http.put(`${this.url_diarybook}${payload.id}/`, payload)
      return result
    },
    async retrieveRequest (url, _payload) {
      const result = await this.$http.get(url + _payload.id + '/', { params: _payload })
      return result
    },
    async retrieveRequestPeriod (url_period, _payload) {
      const result = await this.$http.get(url_period + _payload.id + '/', { params: _payload })
      return result
    },
  },
  filters: {
    status_to_text(value){
      if (value) return 'Sí';
      return 'No';
    },
    format_number: function (value) {
      if (!value) return ''
        return isNaN(value) ? 0 : parseFloat(value).toFixed(2)
    },
    to_date(value) {
      if (!value ) return '-----'
      return moment(value, 'YYYY-MM-DD', 'es').format('DD/MM/YYYY')
    },
    to_text_date(value) {
      if (!value ) return '-----'
      return moment(value, 'YYYY-MM-DD', 'es').format('MMMM Y')
    },
  },
  computed: {
    settings_obj() {
      return this.$store.state.settings_obj;
    },
  },
};

</script>
<style lang="css" scoped>
 .success {
    font-weight: 500;
    background-color: #4aad09;
  }
  .warning {
    font-weight: 500;
    background-color: yellow;
  }
  .danger {
    font-weight: 500;
    background-color: red;
  }
  table {
    font-size: 2em;
  }
  .period{
    float: center;
  }
  .moveRigth{
    display: block;
    float: right;
    margin-right: 20%;
    margin-top: 2%;
  }
  .moveLeft{
    display: block;
    float: left;
  }
  .margin{
    margin-bottom: 50px;
  }
  .md-error {
    color: #ff1744;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-box-shadow: 0 0 0px 1000px #424242 inset;
  }
</style>
